import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col, InputGroup, FormControl, Table, Card } from "react-bootstrap"; // React Bootstrap components
import { FaEdit, FaPlus, FaSearch, FaShoppingCart, FaTrash } from "react-icons/fa"; // Search icon and shopping cart
import Swal from "sweetalert2";
import { Buyer, SearchBuyerForm, Product, SearchProductForm, SealDoc, SealDocProduct } from "../../Types/SaleType"; // Import your types
import { useNavigate } from "react-router-dom";
import "./style.scss";
import moment from "moment-timezone";
import { Axios } from "../../Axios";
import { AddCustomerModal } from "../../component/Components/AddCustomerModal";
import { UpdateCustomerModal } from "../../component/Components/UpdateCustomerModal";

export const Sale: React.FC = React.memo((): JSX.Element => {
  const navigate = useNavigate();

  // Combined useState hook
  const [state, setState] = useState({
    buyers: [] as Buyer[], // For buyers data
    searchBuyer: { Name: "", Phone: "" } as SearchBuyerForm, // For search buyer form
    products: [] as Product[], // For product data
    searchProduct: { Name: "" } as SearchProductForm, // For search product form
    isOpenCutomerModal:false as boolean,
    isOpenCutomerUpModal:false as boolean,
    updateCustomer : {ID:0,Name:"",Phone:"",Address:""} as Buyer 
  });
  const [docState, setDocState] = useState<SealDoc>({
    DocSumAMD: 0,
    DocGiveSumAMD: 0,
    CreateUserID: 0, 
    Notes: '',
    FromID: 0,   
    ToID: null,
    CassaID: 0,     
    products: [],
    debtDtn:null
  });
  

  // const initialBuyers: Buyer[] = [
  //   { ID: 1, Name: "Արամ", Phone: "093123456", Address: "Երևան,"},
  //   { ID: 2, Name: "Արմինե", Phone: "094123456", Address: "Վանաձոր," },
  //   { ID: 3, Name: "Լիանա", Phone: "091234567", Address: "Գյումրի," },
  // ];

  const initialProducts: Product[] = [
    { ID: 1, Name: "Նասկիներ Սպիտակ ու Սև",Count: 15, Price: 1000, Img_url: "https://basket-13.wbbasket.ru/vol1921/part192162/192162529/images/big/1.webp" },
    { ID: 2, Name: "Նասկիներ Սև զարդանաղշերով",Count: 8, Price: 2000, Img_url: "https://basket-13.wbbasket.ru/vol1955/part195558/195558220/images/c246x328/2.webp" },
    { ID: 3, Name: "Նասկիներ Ադիդաս",Count: 10, Price: 3000, Img_url: "https://basket-15.wbbasket.ru/vol2276/part227676/227676081/images/c246x328/1.webp" },
  ];
  const yerevanTime:Date = moment().tz("Asia/Yerevan").toDate();
  
  useEffect(() => {
    const checkLogin = async () => {
      try {
        const IsLogin = await Axios.get("auth/check-auth");
        // console.log(IsLogin.data.valid);
        if(IsLogin.data.valid == false)
        {
          navigate("/");
        }
      } catch (error) {
        navigate("/");
      }
    };
  
    checkLogin();
  }, []);
  

  // Buyer search
  const handleSearchBuyer = async () => {
    try {
      if (state.searchBuyer.Name.trim() === "" && state.searchBuyer.Phone.trim() === "") {
        setState((prevState) => ({ ...prevState, buyers: [] }));
        return;
      }
      setDocState({...docState,ToID:null})
      const res = await Axios.get(`persons?Name=${state.searchBuyer.Name.trim()}`);
      console.log(res)
      setState((prevState) => ({
        ...prevState,
        buyers: res.data
      }));
      
    } catch (error) {
      Swal.fire("Սխալ", "Տվյալների որոնման ընթացքում եղավ սխալ", "error");
    }
  };

  // Product search
  const handleSearchProduct = () => {
    try {
      if (state.searchProduct.Name.trim() === "") {
        setState((prevState) => ({ ...prevState, products: [] }));
        return;
      }
      setState((prevState) => ({
        ...prevState,
        products: initialProducts.filter((e) => e.Name.toLowerCase().includes(state.searchProduct.Name.toLowerCase())),
      }));
    } catch (error) {
      Swal.fire("Սխալ", "Ապրանքների որոնման ընթացքում եղավ սխալ", "error");
    }
  };

  const updateSearchProduct = (Name: string) => {
    setState((prevState) => ({
      ...prevState,
      searchProduct: { Name },
    }));
  };

  // Handler for Enter key press
  const handleKeyPress = (e: React.KeyboardEvent, searchType: "buyer" | "product") => {
    if (e.key === "Enter") {
      if (searchType === "buyer") {
        handleSearchBuyer();
      } else {
        handleSearchProduct();
      }
    }
  };

  const selectCustomer = (customerID:number) => {
    setState({...state,buyers:state.buyers.filter(e=>e.ID == customerID)})
    setDocState({...docState,ToID:customerID})
  }


  const addProductToDoc = (product: Product) => {
    
      const index = docState.products.findIndex(e=> e.ProductID == product.ID);
      if(index == -1){
        docState.products.push(
          {
            ProductID:product.ID,
            ProductName:product.Name,
            ProductPriceAMD:product.Price,
            ProductPriceEndAMD:product.Price,
            ProductDesc:0,
            ProductCount:1,
            ProductPriceEndSumAMD:product.Price,
            ProductAvailableCount:product.Count
          }
        )
        docState.DocSumAMD = docState.products.reduce((a,b)=> a + b.ProductPriceEndSumAMD,0)
        docState.DocGiveSumAMD = docState.products.reduce((a,b)=> a + b.ProductPriceEndSumAMD,0)

        setDocState({...docState})
      }
  };
  
  
  // ✅ Ապրանքի գնի կամ քանակի փոփոխություն
  const handleChangeProduct = (index: number, value: number) => {
    console.log(value)
    console.log(docState.products[index].ProductAvailableCount)
    if(value <= 0)
    {
      docState.products[index].ProductCount = 1;
      docState.products[index].ProductPriceEndSumAMD = 1 * docState.products[index].ProductPriceEndAMD;
    }
    else if(value > docState.products[index].ProductAvailableCount)
      {
      docState.products[index].ProductCount = docState.products[index].ProductAvailableCount;
      docState.products[index].ProductPriceEndSumAMD = docState.products[index].ProductAvailableCount * docState.products[index].ProductPriceEndAMD;
    }
    else
    {
      docState.products[index].ProductCount = value;
      docState.products[index].ProductPriceEndSumAMD = value * docState.products[index].ProductPriceEndAMD;
    }
    docState.DocSumAMD = docState.products.reduce((a,b)=> a + b.ProductPriceEndSumAMD,0)
    docState.DocGiveSumAMD = docState.products.reduce((a,b)=> a + b.ProductPriceEndSumAMD,0)
    setDocState({...docState});
  };

  const handleDiscountChange = (index: number, discountPrice: number) => {

    if(discountPrice < docState.products[index].ProductPriceAMD / 2)
    {
      docState.products[index].ProductPriceEndAMD = docState.products[index].ProductPriceAMD;
      docState.products[index].ProductPriceEndSumAMD = docState.products[index].ProductCount * docState.products[index].ProductPriceAMD;
    }
    else
    {
      docState.products[index].ProductPriceEndAMD = discountPrice;
      docState.products[index].ProductPriceEndSumAMD = docState.products[index].ProductCount * discountPrice;
    }
    docState.DocSumAMD = docState.products.reduce((a,b)=> a + b.ProductPriceEndSumAMD,0)
    docState.DocGiveSumAMD = docState.products.reduce((a,b)=> a + b.ProductPriceEndSumAMD,0)
    setDocState({...docState});
  };
  
  const changeInputCount = (index: number, discountPrice: number) => {
    docState.products[index].ProductCount = discountPrice;
    setDocState({...docState});
  }
  const changeInputDesc = (index: number, discountPrice: number) => {
    docState.products[index].ProductPriceEndAMD = discountPrice;
    setDocState({...docState});
  }
  const removeProductFromDoc = (index: number) => {
    docState.products = docState.products.filter((_, i) => i !== index);
    docState.DocSumAMD = docState.products.reduce((a,b)=> a + b.ProductPriceEndSumAMD,0)
    docState.DocGiveSumAMD = docState.products.reduce((a,b)=> a + b.ProductPriceEndSumAMD,0)
    setDocState({...docState});
  };
  const changeDocGiveSumAMD = (discountPrice: number) => {
    if(discountPrice < 0)
    {
      setDocState(prevDocState => ({
        ...prevDocState,
        DocGiveSumAMD: 0,
        debtDtn:docState.debtDtn == null? yerevanTime: docState.debtDtn
      }));
    }
    else if( discountPrice > docState.DocSumAMD){
      setDocState(prevDocState => ({
        ...prevDocState,
        DocGiveSumAMD: docState.DocSumAMD,
        debtDtn:docState.debtDtn == null? yerevanTime: docState.debtDtn
      }));
    }else if (discountPrice == docState.DocSumAMD)
    {
      setDocState(prevDocState => ({
        ...prevDocState,
        DocGiveSumAMD: discountPrice,
        debtDtn:null
      }));
    }
    else{
      setDocState(prevDocState => ({
        ...prevDocState,
        DocGiveSumAMD: discountPrice,
        debtDtn:docState.debtDtn == null? yerevanTime: docState.debtDtn
      }));
    }
  };
  const changeDocDebt = (debtDate : Date | null) => {
    if(debtDate == null){
      setDocState((prevState) => ({
        ...prevState,
        debtDtn: debtDate,
      }));
    }
    else if(debtDate < yerevanTime){
      setDocState((prevState) => ({
        ...prevState,
        debtDtn: yerevanTime,
      }));
    }else{
      setDocState((prevState) => ({
        ...prevState,
        debtDtn: debtDate,
      }));
    }
  };
  

  const docSale = () => {
    if(docState.products.length == 0){
      Swal.fire({
        icon:"error",
              text: "Ընտրեք վաճառքի ապրանքները!",
              confirmButtonText : "Լավ"
            });    
      return;
    }
    if((docState.ToID == null || docState.ToID == 0) && (docState.DocSumAMD != docState.DocGiveSumAMD)){
      Swal.fire({
              icon:"error",
              text: "Ընտրեք գնորդին!",
              confirmButtonText : "Լավ"
            });    
      return;
    }
    const resSell = "OK";
    if(resSell.startsWith("OK")){
      Swal.fire({
        position:"center",
        icon: "success",
        title: "Վաճառքը հաջողությամբ կատարվեց",
        showConfirmButton: false,
        timer: 1500
      });      
      docCansel();
    }else{
      Swal.fire({
        icon:"error",
        text: "Վաճառքը չհաջողվեց!",
        confirmButtonText : "Լավ"
      });    
    }
  }

  const docCansel = () => {
    setState({
      buyers: [] as Buyer[], // For buyers data
      searchBuyer: { Name: "", Phone: "" } as SearchBuyerForm, // For search buyer form
      products: [] as Product[], // For product data
      searchProduct: { Name: "" } as SearchProductForm,
      isOpenCutomerModal:false as boolean,
      isOpenCutomerUpModal:false as boolean,
      updateCustomer : {ID:0,Name:"",Phone:"",Address:""} as Buyer 
    })
    setDocState({
      DocSumAMD: 0,
      DocGiveSumAMD: 0,
      CreateUserID: 0, 
      Notes: '',
      FromID: 0,   
      ToID: null,
      CassaID: 0,     
      products: [],
      debtDtn:null
    })
  }

  // Create Customer
  const openCustomerModal = () => {
    setState({...state,isOpenCutomerModal:true})
  }
  const closeCustomerModal = () => {
    setState({...state,isOpenCutomerModal:false})
  }
  const saveCustomerModal = async(data:Buyer) => {
    try
    {
      const res:any = await Axios.post("persons",data);
      console.log(res)
      if(res?.data?.actionTaken === true){
        setState({
          ...state,
          buyers:[{...data,ID:res?.data?.ID}],
          isOpenCutomerModal:false
        });
        setDocState({...docState,ToID :res?.data?.ID });
      }
    }
    catch(err)
    {
      Swal.fire({
        icon:"error",
        text: "Գնորդ ավելացնել չհաջողվեց!",
        confirmButtonText : "Լավ"
      });   
    }
  }

  // Update Customer
  const openCustomerUpModal = (data:Buyer) => {
    console.log(data)
    setState({...state,isOpenCutomerUpModal:true,updateCustomer:data})
  }
  const closeCustomerUpModal = () => {
    setState({...state,isOpenCutomerUpModal:false})
  }
  const updateCustomerModal = async(data: Buyer) => {
    try {
        const res: any = await Axios.put(`persons/${data.ID}`, data);
        if (res?.data?.actionTaken == true) {
          console.log(res);
            setState({
                ...state,
                buyers: [{ ...data, ID: data.ID }],
                isOpenCutomerUpModal: false,
            });
            setDocState({ ...docState, ToID: data.ID });
        }
    } catch (err) {
        Swal.fire({
            icon: "error",
            text: "Գնորդը թարմացնել չհաջողվեց!", // "Failed to update customer!"
            confirmButtonText: "Լավ",
        });
    }
};

  return (
    <div className="sell">
      <Container>
      <AddCustomerModal 
        showModal = {state.isOpenCutomerModal} 
        hideModal = {closeCustomerModal} 
        saveModal = {saveCustomerModal}
      />
      <UpdateCustomerModal
      showModal = {state.isOpenCutomerUpModal}
      hideModal = {closeCustomerUpModal}
      updateModal = {updateCustomerModal}
      customer = {state.updateCustomer}
      />
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <h2>Գնորդի որոնում</h2>
        <button onClick={openCustomerModal} style={{border:"0px",background:"transparet"}}>
          <FaPlus></FaPlus>
        </button>
      </div>
        <div className="buyer-search-form" style={{marginBottom: "10px"}}>
          <Row className="align-items-center">
            <Col md={6} xs={6}>
              <Form.Group controlId="formName">
                <Form.Control
                  type="text"
                  placeholder="Անուն"
                  onChange={(e) => setState({ ...state, searchBuyer: { ...state.searchBuyer, Name: e.target.value } })}
                  onKeyDown={(e) => handleKeyPress(e, "buyer")} // Enter key press for buyer search
                />
              </Form.Group>
            </Col>
            <Col md={5} xs={5}>
              <Form.Group controlId="formPhone">
                <Form.Control
                  type="text"
                  placeholder="Հեռախոս"
                  onChange={(e) => setState({ ...state, searchBuyer: { ...state.searchBuyer, Phone: e.target.value } })}
                  onKeyDown={(e) => handleKeyPress(e, "buyer")} // Enter key press for buyer search
                />
              </Form.Group>
            </Col>
            <Col md={1} xs={1} className="d-flex justify-content-end">
              <button onClick={handleSearchBuyer}>
                <FaSearch />
              </button>
            </Col>
          </Row>
        </div>

        {state.buyers.length > 0 && (
          <div className="buyer-details mt-2">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Անուն</th>
                  <th>Հեռախոս</th>
                  <th>Հասցե</th>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {state.buyers.map((buyer) => (
                  <tr key={buyer.ID} onClick={() => selectCustomer(buyer.ID)}
                   className={buyer.ID == docState.ToID ? "color_buyer" : ""} >
                    <td>{buyer.Name}</td>
                    <td>{buyer.Phone}</td>
                    <td>{buyer.Address}</td>
                    <td>
                      <button onDoubleClick={()=>openCustomerUpModal(buyer)}>
                        <FaEdit/>
                      </button>
                      </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        <h2>Ապրանքի որոնում</h2>
        <div className="product-search-form">
          <Row className="align-items-center">
            <Col md={11} xs={11}>
              <Form.Group controlId="formProductName">
                <Form.Control
                  type="text"
                  placeholder="Ապրանքի անուն"
                  value={state.searchProduct.Name}
                  onChange={(e) => updateSearchProduct(e.target.value)}
                  onKeyDown={(e) => handleKeyPress(e, "product")} // Enter key press for product search
                />
              </Form.Group>
            </Col>
            <Col md={1} xs={1} className="d-flex justify-content-end">
              <button onClick={handleSearchProduct} >
                <FaSearch />
              </button>
            </Col>
          </Row>
        </div>

        <div style={{ overflowX: "auto", padding: "10px" }}>
          <Row className="mt-3" style={{ flexWrap: "nowrap", minWidth: "max-content" }}>
            {state.products.map((product) => (
              <Col key={product.ID} md={4} xs={4} style={{ flex: "0 0 auto", marginRight: "20px" ,minWidth:"270px"}}>
                <Card className="product-card" style={{ width: "100%", border: "1px solid #ddd", borderRadius: "8px" }}>
                  <Card.Img
                    variant="top"
                    src={product.Img_url}
                    alt={product.Name}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                  />
                  <Card.Body className="d-flex flex-column justify-content-between" style={{ padding: "15px" }}>
                    <div>
                      <Card.Title
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          marginBottom: "5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        title={product.Name} // Tooltip ցուցադրելու համար
                      >
                        {product.Name}
                      </Card.Title>
                      <Card.Text style={{ fontSize: "14px",fontWeight:"bold",color:"blue"}}>{product.Price} դր.</Card.Text>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span style={{ fontSize: "14px", fontWeight: "bold", color: "#333" }}>
                        Քանակ: {product.Count}
                      </span>

                      <button
                        className="add-to-cart"
                        style={{ padding: "8px 10px", borderRadius: "50%" }}
                        onClick={() => addProductToDoc(product)}>
                        { docState.products.findIndex(e=>e.ProductID == product.ID) == -1?
                        <FaShoppingCart />
                        :
                        <div style={{width:"16px",height:"16px"}}></div>
                        }
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>

        { true && ( // docState.products.length > 0
          <>
            <h3>Ընտրված Ապրանքներ</h3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {docState.products.map((product, index) => (
                <div key={index} style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '8px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                    <span style={{ fontWeight: 'bold' }}>{product.ProductName}</span>
                    <button onClick={() => removeProductFromDoc(index)} style={{ padding: '5px 10px' ,color:"red"}}>
                      <FaTrash />
                    </button>
                  </div>
                  <div style={{display:"flex",gap:"20px"}}>
                    <div style={{ marginBottom: '8px' }}>
                      <label>Քանակ : </label>
                      <Form.Control
                        type="number"
                        value={product.ProductCount}
                        onChange={(e) => changeInputCount(index, Number(e.target.value))}
                        onBlur={(e) => {
                          handleChangeProduct(index, Number(e.target.value)); // Trigger on blur
                        }}
                        // min={1}
                        style={{ width: '80px', display: 'inline-block' }}
                        />
                    </div>
                  
                    {/* Զեղչի գին փոխելու դաշտ */}
                    <div>
                      <label>Զեղչված Գին : </label>
                      <Form.Control
                        type="number"
                        value={product.ProductPriceEndAMD}
                        onChange={(e) => {
                          const newDiscountPrice = Number(e.target.value);
                          changeInputDesc(index, newDiscountPrice);
                        }}
                        onBlur={(e) => {
                          handleDiscountChange(index, Number(e.target.value)); // Trigger on blur
                        }}
                        min={0}
                        style={{ width: '80px', display: 'inline-block' }}
                        />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row',alignItems:"center",justifyContent:"space-between" }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {
                        product.ProductPriceAMD != product.ProductPriceEndAMD
                        ?
                        <span style={{ textDecoration: 'line-through', color: '#888' }}>
                          {product.ProductPriceAMD} դր.
                        </span>
                        :
                        <></>
                      }
                      <span style={{ fontWeight: 'bold', color: '#333' }}>
                        {product.ProductPriceEndAMD} դր.
                      </span>
                    </div>
                    <div style={{fontWeight:"700",color:"blue"}}>Ապր. գումար ։ {product.ProductPriceEndSumAMD} դր․</div>
                  </div>

                </div>
              ))}
            </div>
          </>
        )}

        <div style={{ 
          display: 'flex', 
          // flexDirection: 'column',  // Դաշտերը լինում են իրար տակ
          flexWrap:"wrap",
          gap: '20px',  // Հեռավորություն դաշտերի միջև
          marginTop: '20px',
          marginBottom:'20px',
          padding: '0 0',  // Մատուցման ճիշտ դիրքավորումը
        }}>
          {/* Ընդհանուր գումար */}
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',  // Թեքստն ու ինփուտ դաշտը իրար կողքի
            fontWeight: 'bold', 
            fontSize: '18px' 
          }}>
            <label style={{ marginRight: '10px' }}>Վաճ․ Գումար :</label>
            <Form.Control
            disabled = {true}
              type="number"
              value={docState.DocSumAMD || 0}
              style={{
                width: '100%',
                maxWidth: '150px',
              }}
            />
          </div>

          {/* Հաճախորդի վճարած գումար */}
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',  // Թեքստն ու ինփուտ դաշտը իրար կողքի
            fontWeight: 'bold', 
            fontSize: '18px',
          }}>
            <label style={{ marginRight: '10px' }}>Վճ․ Գումար &nbsp;&nbsp;&nbsp;:</label>
            <Form.Control
              type="number"
              value={docState.DocGiveSumAMD || 0}
              onBlur={(e) => changeDocGiveSumAMD(Number(e.target.value))}
              onChange = {(e) => {
                setDocState(prevDocState => ({
                  ...prevDocState,
                  DocGiveSumAMD: Number(e.target.value)
                }));
              }}
              style={{
                width: '100%',
                maxWidth: '150px',
              }}
            />
          </div>
          { docState.DocSumAMD != docState.DocGiveSumAMD ?
            <div style={{ 
              display: 'flex', 
              alignItems: 'center',  // Թեքստն ու ինփուտ դաշտը իրար կողքի
              fontWeight: 'bold', 
              fontSize: '18px',
            }}>
              <label style={{ marginRight: '10px',color:"red" }}>Պարտք &nbsp;&nbsp;&nbsp;:</label>
              <Form.Control
                type="number"
                value={docState.DocSumAMD - docState.DocGiveSumAMD}
                disabled = {true}
                style={{
                  width: '100%',
                  maxWidth: '120px',
                  color:"red"
                }}
              />
              <input
                type="date" // Ժամը չցուցադրելու համար օգտագործել "date"
                id="datetime"
                className="form-control"
                style={{
                  maxWidth: "120px",
                  marginLeft:"10px"
                }}
                value={docState.debtDtn ? docState.debtDtn.toISOString().slice(0, 10) : ''} // Պահել միայն ամսաթիվը
                onChange={(e) => changeDocDebt(e.target.value ? new Date(e.target.value) : null)} // Միանգամից Date օբյեկտ կամ null փոխանցում
              />
            </div>
            :
            <></>
          }
          <Button variant="primary" onClick={docSale} 
            style={{ width: '150px',background:"#0d6efd",color:"white",height:"35px" }}>
            Վաճառել
          </Button>
          <Button variant="secondary" onClick={docCansel}
            style={{ width: '150px',background:"#6c757d",color:"white",height:"35px" }}>
            Չեղարկել
          </Button>
        </div>
      </Container>
    </div>
  );
});
