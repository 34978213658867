import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Buyer } from "../../../Types/SaleType";

export const AddCustomerModal = React.memo(({showModal,hideModal,saveModal}:any)=>{
    // const dispatch = useAppDispatch();
    const { register, handleSubmit, reset, formState: { errors } } = useForm<Buyer>();
  const save = (data:Buyer)=>{
    saveModal(data);
    reset();
  }
    return <Modal show={showModal} onHide={hideModal}  centered>
        <Modal.Header closeButton>
        <Modal.Title>Ավելացնել Գնորդ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit(save)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Անուն</Form.Label>
                    <Form.Control type="text" {...register("Name",{required:"Անունը լրացված չէ!!",minLength:{value:3,message:"Անունը պետք է լինի գոնե 3 տառ!!"}})} />
                    {errors.Name ? <Form.Text className="text-muted text-danger">
                    {errors.Name?.message}
                    </Form.Text>:<></>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Հեռախոս</Form.Label>
                    <Form.Control type="text" {...register("Phone",{required:"Հեռախոսը լրացված չէ!!",minLength:{value:3,message:"Հեռախոսը պետք է լինի գոնե 9 թիվ!!"}})} />
                    {errors.Phone ? <Form.Text className="text-muted text-danger">
                    {errors.Phone?.message}
                    </Form.Text>:<></>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Հասցե</Form.Label>
                    <Form.Control type="text" {...register("Address",{required:"Հասցեն լրացված չէ!!",minLength:{value:3,message:"Հասցեն պետք է լինի գոնե 4 տառ!!"}})} />
                    {errors.Address ? <Form.Text className="text-muted text-danger">
                    {errors.Address?.message}
                    </Form.Text>:<></>}
                </Form.Group>
                <Modal.Footer style={{paddingBottom:"0px"}}>
                    <Button variant="default" onClick={()=>hideModal(false)}>
                        Չեղարկել
                    </Button>
                    <Button variant="primary" type="submit">Պահպանել</Button>
                </Modal.Footer>
            </Form>
        </Modal.Body> 
  </Modal>
})