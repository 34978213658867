import axios from "axios";

axios.defaults.withCredentials = true;
// export const Axios = axios.create({
//     baseURL: 'http://localhost:8800/api/'
// })


export const Axios = axios.create({
    baseURL: 'https://api-sale.dgspace.info/api/'
})  

