import React from "react";
import { Outlet } from "react-router-dom";
import './style.scss'
import { Menu } from "../../component/Menu";

export const Layout:React.FC=React.memo(():JSX.Element=>{
    return <div className="layout">
        <Menu/>
        <Outlet/>
    </div>
})