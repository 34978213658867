import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { MyRouter } from "./component/MyRouter";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <MyRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
