import userEvent from "@testing-library/user-event";
import { createUserWithEmailAndPassword, onAuthStateChanged, User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.scss'
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { updateDoc } from "firebase/firestore";
export const Profile: React.FC = React.memo((): JSX.Element => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<any>();
    
    const [bool, setBool] = useState<boolean>(false)
    const navigate = useNavigate()
    useEffect(() => {
        // USER CHECK
    }, [])
    return <div className="profile">
        <div>

        </div>
    </div>
})