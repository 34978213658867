import React, { useEffect, useState } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { FaSignOutAlt, FaShoppingCart, FaExchangeAlt, FaClipboardList, FaBars } from "react-icons/fa"; // FaBars ավելացված
import "./style.scss";
import { Axios } from "../../Axios";

export const Menu: React.FC = React.memo((): JSX.Element => {
  const [bool, setBool] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation(); // Ստանալ ընթացիկ URL-ը

  useEffect(() => {
    // const checkLogin = async () => {
    //   try {
    //     const IsLogin = await Axios.get("auth");
    //     if(IsLogin.data.valid == true)
    //     {
    //       setBool(true);
    //     }else{
    //       setBool(false);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching login status:", error);
    //   }
    // };
    // checkLogin();
    if(location.pathname == "/")
    {
        setBool(false);
    }
    else
    {
      setBool(true);
    }
    setMenuOpen(false);
  }, [location]);

  const signOut = async () => {
    const IsLogin = await Axios.post("auth/logout");
    console.log(IsLogin.data.logout == true)
    {
      navigate("/")
      setBool(false)
    }
  };

  const handleMenuClick = () => {
    setMenuOpen(false);
  };

  return (
    <div>
      {bool ? (
        <div className="menu">
          <nav>
            <button className={`hamburger ${menuOpen ? "active" : ""}`} onClick={() => setMenuOpen(!menuOpen)}>
              <FaBars /> {/* ☰ փոխարինվեց FaBars icon-ով */}
            </button>

            <ul className={`menu-list ${menuOpen ? "open" : ""}`}>
              <>
                <li><Link to="/sale" onClick={handleMenuClick}><FaShoppingCart /> Վաճառք</Link></li>
                <li><Link to="/purchase" onClick={handleMenuClick}><FaClipboardList /> Առք</Link></li>
                <li><Link to="/movement" onClick={handleMenuClick}><FaExchangeAlt /> Տեղաշարժ</Link></li>
                <li><Link to="/Product_directory" onClick={handleMenuClick}><FaClipboardList /> Ապրանքի տեղեկատու</Link></li>
                <li><button onClick={signOut}><FaSignOutAlt /> Ելք</button></li>
              </>
            </ul>
          </nav>
        </div>
      ) : (
        <>
        </>
      )}
    </div>
  );
});
