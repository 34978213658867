import React, { Children } from "react";
import { useRoutes } from "react-router-dom";
import './style.scss'
import { Error } from "../../pages/Error";
import { Layout } from "../../pages/Layout";
import { Login } from "../../pages/Login";
import { Profile } from "../../pages/Profile";
import { Sale } from "../../pages/Sale";

import path from "path";
import { Purchase } from "../../pages/Purchase";
import { Movement } from "../../pages/Movement";
import { Product_directory } from "../../pages/Product_directory";

export const MyRouter:React.FC=React.memo(()=>{
    const router=useRoutes([
        {
            path:'/',
            element:<Layout/>,
            children:[
                {
                    path:'',
                    element:<Login/>
                },
                {
                    path:'profile',
                    element:<Profile/>
                },
                {
                    path:'sale',
                    element:<Sale/>
                },
                {
                    path:'purchase',
                    element:<Purchase/>
                },
                {
                    path:'movement',
                    element:<Movement/>
                },
                {
                    path:'Product_directory',
                    element:<Product_directory/>
                }
            ]
        },{
            path:'*',
            element:<Error/>
        }
    ])
    return router
})