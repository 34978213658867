import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.scss'
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";


export const Purchase: React.FC = React.memo((): JSX.Element => {
    const navigate = useNavigate()
    useEffect(() => {
        // USER CHECK
    }, [])

    return <div className="Purchase">
        <div>
            <p>Purchase</p>
        </div>
    </div>
})